import React, {useEffect, useRef, useState, lazy, Suspense} from 'react';
import { useInView } from 'react-intersection-observer';
import GlobalStyles from '../styles/global';
import { Helmet } from "react-helmet";
import PropagateLoader from 'react-spinners/PropagateLoader';
import * as S from '../styles/sales_page';
import {Whatsapp} from '@styled-icons/fa-brands/Whatsapp';
import Cover2 from '../components/Cover2';
import AvisoLegal from '../components/AvisoLegal';


// import About from '../components/About';
// import AntesEDepois from '../components/AntesEDepois';
// import MetodoEsbelta from '../components/MetodoEsbelta';
// import Beneficios from '../components/Beneficios';
// import Desafio28Dias from '../components/Desafio28Dias';
// import Mission from '../components/Mission';
// import Warranty from '../components/Warranty';
// import Price from '../components/Price';
// import SocialProof1 from '../components/SocialProof1';
// import SocialProof2 from '../components/SocialProof2';

const About = lazy(() => import('../components/About'));
const Warranty = lazy(() => import('../components/Warranty'));
const Price = lazy(() => import('../components/Price'));
const AntesEDepois = lazy(() => import('../components/AntesEDepois'));
const Desafio28DiasShort = lazy(() => import('../components/Desafio28DiasShort'));
const SocialProof1 = lazy(() => import('../components/SocialProof1'));
const SocialProof2 = lazy(() => import('../components/SocialProof2'));


const SalesPage = () => {
    const [ref, inView, entry] = useInView();
    const isSSR = typeof window === "undefined"
    const cover = useRef(null);
    const contact = useRef(null);
    const priceRef = useRef(null);
    const [contactVisible, setContactVisible] = useState(false);

    useEffect(() => {
        const setContactVisibility = () => {
          if (!entry) return;
          if (entry.isIntersecting) {
              setContactVisible(true);
          }
        }
        setContactVisibility()
      },[entry])

    const onButtonClick = () => {
        priceRef.current.scrollIntoView({ behavior: 'smooth'});
    }          
                                                    
    return (
        <S.Layout>  
        <S.PageWrapper>
            <GlobalStyles />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Método Esbelta</title>
                <link rel="canonical" href="https://metodoesbelta.com" />
            </Helmet>
            <S.Cover ref={cover}>
                <Cover2/> 
            </S.Cover>
            {
            !isSSR &&
            <Suspense fallback={PropagateLoader}>
              <SocialProof1 />
            </Suspense>
            }
            {
            !isSSR &&
            <Suspense fallback={PropagateLoader}>
              <Desafio28DiasShort onButtonClick={onButtonClick} ref={ref}/>
            </Suspense>
            }
            {
            !isSSR &&
            <Suspense fallback={PropagateLoader}>
                <About />
            </Suspense>
            }
            {/* {
            !isSSR &&
            <Suspense fallback={PropagateLoader}>
                <AntesEDepois onButtonClick={onButtonClick}/>
            </Suspense>
            } */}
            {
            !isSSR &&
            <Suspense fallback={PropagateLoader}>
              <SocialProof2 />
            </Suspense>
            }
            {
            !isSSR &&
            <Suspense fallback={PropagateLoader}>
                <Price ref={priceRef} />
            </Suspense>
            }
            {
            !isSSR && 
            <Suspense fallback={PropagateLoader}>
                <Warranty />
            </Suspense>
            }
            <S.AvisoLegal>
                <AvisoLegal />
            </S.AvisoLegal>
            { contactVisible &&
                <S.Contact ref={contact}>
                    {/* <span>Alguma dúvida?</span>                     */}
                    <S.Whatsapp href="https://wa.me/5551992677033?text=Ol%c3%a1%2c+gostaria+de+informa%c3%a7%c3%b5es+sobre+o+Desafio+28+Dias+do+M%c3%a9todo+Esbelta%2c+por+favor." className="float" target="_blank">
                        <Whatsapp className="icon" />
                    </S.Whatsapp>
                </S.Contact>
            }
        </S.PageWrapper>
    </S.Layout>
    );
}


export default SalesPage;