import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { StaticImage } from 'gatsby-plugin-image';
import Youtube from './youtube';
import CTAButtonBuy from './CTAButtonBuy';


const CoverCopy = styled.div`
    display: grid;
    grid-template-columns: repeat(6,1fr);
    height: 100%;
    padding: 1rem;

    ${media.lessThan("large")`
        /* flex-direction: column; */
        align-items: center;
        justify-content: space-between;
    `}


`
const CoverLogo = styled.div`
    grid-row: 1/2;
    grid-column: 3/5;
    height: auto;
    width: 20rem;
    margin: 0 auto 2rem;
    
    ${media.lessThan("medium")`
        width: 10rem;
    `}
`

const HeadlineWrapper = styled.div`
    grid-column: 1/4;
    grid-row: 2/5;
    z-index: 100;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 0 2rem;
    color: white;

    ${media.lessThan("large")`
        margin: 0 2rem 2rem;
        text-align: center;        
        grid-column: 1/-1;
        grid-row: 2/3;
    `}
        
        ${media.lessThan('700px')`
        margin: 0 1rem;
        grid-column: 1/-1;
        grid-row: 2/3;
        padding: 0;
    `}
`

const Headline = styled.h1`
    font-size: 6vmin;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    margin-bottom: 2rem;
    color: var(--purple);
    /* text-shadow: 1px 1px 0 rgb(34 30 31 / 75%); //1px 1px 1px var(--postColor); */
    
    span.bigger {
        text-transform: uppercase;
        color: white;
        background: var(--orange);
    }
    
    ${media.lessThan('large')`
        margin: 1rem 0;
        font-size: 5vmin;
    `}

    ${media.lessThan('medium')`
        font-size: 7vmin;
    `}

    ${media.lessThan("370px")`
        margin-bottom: 1rem;
    `}
`

const SubHeadline = styled.h2`
    font-size: 3vmin;
    font-weight: 600;
    line-height: 1.4;
    color: var(--pink);
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 2rem;
    /* text-align: center; */
    span {
        background: var(--pink);
        color: var(--white);
        font-weight: 700;
        font-size: 1.8rem;
    }


    ${media.lessThan("large")`
        span {
            line-height: 1.6;
        }   
    `}
    
    ${media.lessThan("medium")`
        font-size: 4vmin;

        span {
            font-size: 3.2vmin;
            line-height: 1.6;
        }
    `}

    ${media.lessThan("400px")`
        /* font-size: 14px; */

        span {
            font-size: 3.1vmin;
            line-height: 1.4;
        }
    `}
`

const ByCharline = styled.h3`
    font-size: 3.5vmin;
    font-weight: 500;
    color: var(--purple);
    font-family: 'Caveat', sans-serif;
    align-self: start;

    ${media.lessThan("large")`
        align-self: center;
        font-size: 4.5vmin;
    `}
`

const VideoWrapper = styled.div`
    grid-column: 4/-1;
    grid-row: 2/5;
    margin: 2rem;
    
    ${media.lessThan("large")`
        grid-column: 1/-1;
        grid-row: 3/4;
    `}
        
    ${media.lessThan("medium")`
        grid-column: 1/-1;
        grid-row: 3/4;
        margin: 0;
    `}
`

const CTAButtonWrapper = styled.div`
    grid-column: 1/-1; 
    grid-row: 5/6;
    z-index: 100;
    margin: 0 auto;
    
    ${media.lessThan("large")`
        grid-row: 4/5;
        margin: 0 1rem;
    `}
`


const Cover2 = ({headline, subheadline}) => (
        <CoverCopy>
            <CoverLogo>
                <StaticImage src="../images/logo-esbelta-desafio.png" alt="logo" />
            </CoverLogo>
            <HeadlineWrapper>
                { headline ? 
                    <Headline>
                        {headline} 
                    </Headline> :
                    <Headline>
                        Emagreça de Forma Saudável, Sem Deixar de Comer o Que Você Gosta Nem Passar Fome
                    </Headline>
                }
                    {/* O Único Método De Emagrecimento 100% Seguro Que Te Fará Perder de 5 a 10kg em Apenas 28 Dias, Sem Deixar de Comer o Que Você Gosta */}
                    {/*"Quer Perder Até 10 Kg em 28 dias, Sem Abrir Mão de Suas Comidas Favoritas, e Sem Passar Fome?" */}
                    {/* Se Você é Mulher e Precisa Emagrecer, Mas Não Quer Passar Fome, Está No Lugar Certo */}
                    {/* Perca de 5 a 10 Kg Em Apenas 4 Semanas - Sem Sofrer Nem Passar Fome */}
                {subheadline ? 
                    <SubHeadline>
                            {subheadline}
                    </SubHeadline> :
                    <SubHeadline>
                        O Único Método De Emagrecimento 100% Seguro, Que Te Dá o Passo a Passo de Como Se Alimentar Bem e Ainda Assim Emagrecer, Ganhar Saúde e Alcançar o Corpo Que Você Sempre Sonhou
                        {/* Se Você Deseja Emagrecer, Ganhar Saúde e Se Tornar Uma Mulher Mais Atraente, Saudável e Feliz, Mas Não Quer Deixar de Comer Pizza, Chocolate e Outras Delícias, Você Encontrou o Que Procurava. */}
                    </SubHeadline> 
                }
                <ByCharline>por Nutricionista Charline Tormen</ByCharline>
            </HeadlineWrapper>
            <VideoWrapper>
                <Youtube id="scr_61fb4005b73d970008e11646" url="https://cdn.converteai.net/92fcdc7e-6465-4fcd-925a-a8ec12601466/players/61fb4005b73d970008e11646/player.js" />
            </VideoWrapper>
            <CTAButtonWrapper>
                <CTAButtonBuy href="https://go.metodoesbelta.com/pay/desafio-28-dias">Quero Ser Magra Com 81 centavos Por Dia</CTAButtonBuy>
            </CTAButtonWrapper>
        </CoverCopy>
)

export default Cover2;